<template>
  <main class="CteManagement">
    <!-- Sub Header -->
    <TheSubHeader
      ref="thesubheader"
      icon="status-alt"
      :title="pageTitle"
      :actions="['back']"
      type="tabs"
      organizer-gap="1rem"
      :tabs="tabs"
      @changeTab="changeCurrentTab($event)"
    >
      <!-- After Buttons -->
      <template #afterButtons>
        <BaseButton
          icon="add"
          color="primary-base"
          label="Cadastrar nova rota"
          class="TheSubHeader__button"
          @click="handlerNew()"
        />
      </template>
      <!-- Filters -->
      <template #filter>
        <section
          class="CteManagement__subHeaderTab CteManagement__subHeaderTab--cte"
        >
          <!-- Periodo -->
          <BaseDatePicker
            v-model="formattedDateRange"
            @input="resetSearch()"
          />

          <!-- ID da Nota -->
          <InputGroup
            v-model="filters.id"
            placeholder="Pesquisar"
            :label="labelFieldId"
            @debounce="resetSearch()"
          />

          <!-- Motorista -->
          <BaseTreeSelect
            v-if="currentView === 'Mdfe'"
            v-model="filters.driver"
            name="Motorista"
            label="Motorista"
            placeholder="Selecione"
            multiple
            suggestions="searchDrivers"
            @input="resetSearch()"
          />
        </section>
      </template>
    </TheSubHeader>
    <!--Content -->
    <section class="CteManagement__Content">
      <component
        :is="currentView"
        ref="component"
        @hook:mounted="childMounted"
        @reset="resetComponent"
        @update="updateComponent"
      />
    </section>
  </main>
</template>
<script>
import { mapActions } from 'vuex'

import { TheSubHeader } from '@/components/organisms'
import { BaseButton, BaseDatePicker, BaseTreeSelect } from '@/components/atoms'
import { InputGroup } from '@/components/molecules'

//Views
import Cte from './Cte/Cte.vue'
import Mdfe from './Mdfe/Mdfe.vue'

export default {
  name: 'CteManagement',
  components: {
    TheSubHeader,

    //Components
    BaseButton,
    BaseDatePicker,
    BaseTreeSelect,
    InputGroup,

    //Views
    Cte,
    Mdfe,
  },
  data() {
    return {
      //Component
      currentView: 'Cte',
      tabs: [
        {
          id: 0,
          component: 'Cte',
          title: 'CTe',
        },
        // {
        //   id: 1,
        //   component: 'Mdfe',
        //   title: 'MDFE',
        // },
      ],

      //Filters
      filters: {
        dateRange: {
          init: null,
          end: null,
        },
        id: '',
        driver: null
      },
    }
  },
  computed: {
    //Filters
    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },

    //Texts
    pageTitle() {
      return `Gestão de ${this.currentView}`
    },

    labelFieldId() {
      return this.currentView !== 'Cte' ? 'ID MDFe' : 'ID da Rota'
    },
  },
  watch: {
    '$route.query.tab': {
      handler: function (tab) {
        const ctxTab = tab
        this.$nextTick(() => {
          if (ctxTab !== undefined) {
            this.checkTabQuery(ctxTab)
          }
        })
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      clearPagination: 'pagination/clearPagination',
    }),

    //Component
    init() {
      this.$refs.component.init(this.filters)
    },

    changeCurrentTab(e) {
      this.currentView = e.component
    },

    checkTabQuery(tab) {
      const hasTab = this.tabs.filter((el) => el.title.toLowerCase() === tab.toLowerCase() )
      if (hasTab.length > 0) {
        this.$refs.thesubheader.currentTab = hasTab[0].id
        this.currentView = hasTab[0].component
      }
    },

    //Filters
    search() {
      this.$refs.component.search(this.filters)
    },

    resetSearch() {
      this.clearPagination().then(() => this.search())
    },

    //Handlers
    handlerNew() {
      this.$refs.component.handlerNew()
    },

    //Emits
    updateComponent() {},
    resetComponent() {},
    childMounted() {
      this.init()
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .CteManagement {
  &__subHeaderTab {
    display: grid;
    grid-column-gap: 1rem;
    align-items: end;
    width: 100%;

    &--cte {
      grid-template-columns: 240px 1fr 1fr 1fr 1fr;

      @media #{$mobile-view} {
        grid-template-columns: 1fr;
        gap: 1rem;
        grid-template-rows: 1fr;
      }
    }
  }

  &__Content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: '. main .';
    grid-column-gap: 1rem;
  }

  &__Table {
    grid-area: main;
    margin-bottom: 50px;
  }
}
/deep/ .BaseTabs {
  &__item {
    width: 150px;
    text-align: center;
  }
}
</style>
