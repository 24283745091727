<template>
  <Dialog
    v-if="show"
    :warning="message"
    label-cancel="Não"
    label-confirm="Sim"
    @confirm="handlerConfirm"
    @cancel="handlerCancel"
  />
</template>
<script>
import { Dialog } from '@/components/molecules'
export default {
  name: 'CteDialog',
  components: {
    Dialog,
  },
  data() {
    return {
      data: null,
      show: false,
      message: '',
      trigger: undefined,
    }
  },
  methods: {
    async handlerConfirm() {
      switch (this.trigger) {
      case 'remove':
        this.$emit('remove', this.data)
        break
      case 'generateAll':
        this.$emit('generateAll', this.data)
        break
      case 'generate':
        this.$emit('generate', this.data)
        break
      case 'regenerateAll':
        this.$emit('regenerateAll', this.data)
        break
      default:
        break
      }

      this.show = false
    },

    //Handlers
    handlerOpen() {
      this.show = true
    },
    
    handlerCancel() {
      this.show = false
    },

    handleRemove(data) {
      this.message = 'Tem certeza que deseja deletar essa CTe?'
      this.trigger = 'remove'
      this.data = data
    },

    handleGenerateAll(data) {
      this.message = 'Tem certeza que deseja gerar todas CTes para esta rota?'
      this.trigger = 'generateAll'
      this.data = data
    },

    handleGenerate(data) {
      this.message = 'Tem certeza que deseja gerar essa CTe?'
      this.trigger = 'generate'
      this.data = data
    },

    handleRegenerateAll(data) {
      this.message = 'Tem certeza que deseja gerar novamente todas CTes para esta rota?'
      this.trigger = 'regenerateAll'
      this.data = data
    },

  },
}
</script>