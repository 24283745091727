<template>
  <div class="CteManagement__Table">
    <DataTable
      :header="mdfes.data.headers"
      :table="mdfes.data.content"
      @download="handlerDownload"
      @see="handleSee"
      @generateMdfe="handlerGenerate"
    />
    <Pagination @page="search()" @paginate="search()" />
  </div>
</template>
<script>
import { ctes } from '@/api'
import { mapActions, mapState } from 'vuex'
import { DataTable, Pagination } from '@/components/molecules'
export default {
  name: 'MdfeTable',
  components: {
    DataTable,
    Pagination,
  },
  data() {
    return {
      filters: {},
    }
  },
  computed: {
    ...mapState({
      mdfes: (state) => state.routes.mdfes,
      page: (state) => state.pagination.page,
      paginate: (state) => state.pagination.paginate,
    }),
  },
  methods: {
    ...mapActions({
      fetchMdfes: 'routes/fetchMdfes',
      generateMdfe: 'routes/generateMdfe',
      downloadMdfe: 'routes/downloadMdfe',
      setLengthItems: 'pagination/setLengthItems',
      setPagination: 'pagination/setPagination',
      setLoading: 'loading/setLoading',
    }),

    //Init
    init(params) {
      this.search(params)
    },

    //Actions
    async search(values) {
      //Check if click is on filters or paginate buttons
      if (values !== undefined) {
        this.filters = values
      }

      const params = {
        obj: {
          date_start: this.filters.dateRange.init,
          date_end: this.filters.dateRange.end,
          id: this.filters.id,
        },
        page: this.page,
        paginate: this.paginate,
      }

      await this.fetchMdfes(params).then(() => {
        this.setPagination(this.mdfes)
      })

      this.$emit('update')
    },

    //Handlers
    async handlerDownload(data) {
      this.setLoading(true)
      await ctes.downloadMdfe(
        { id: data.id },
        (res) => res,
        (error) => error,
        () => this.setLoading(false)
      )
    },

    handleSee(data) {
      this.$router.push(`/routes/management/mdfe/${data.id}`)
    },

    async handlerGenerate(data) {
      this.setLoading(true)
      await ctes.generateMdfe(
        { id: data.id },
        (res) => res,
        (error) => error,
        () => this.setLoading(false)
      )
    },

  },
}
</script>
