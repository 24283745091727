<template>
  <div class="CteManagement__Table">
    <DataTable
      :header="ctesRoutes.data.headers"
      :table="ctesRoutes.data.content"
      @download="handlerDownload"
      @downloadXml="handlerDownloadXml"
      @generateCte="handlerGenerate"
      @regenerateCte="handleRegenerateAll"
      @see="handleSee"
      @remove="handleRemove"
    />
    <Pagination @page="search()" @paginate="search()" />
    <CteDialog ref="CteDialog" @generateAll="generate" @regenerateAll="regenerate" @remove="remove" />
  </div>
</template>
<script>
import { ctes } from '@/api'
import { mapActions, mapState } from 'vuex'
import { DataTable, Pagination } from '@/components/molecules'
import CteDialog from './CteDialog'
export default {
  name: 'CteTable',
  components: {
    DataTable,
    Pagination,
    CteDialog,
  },
  data() {
    return {
      filters: {},

      //Helpers
      updating: false,
      time: null,
      timeOutUpdate: 10 * 1000,
    }
  },
  computed: {
    ...mapState({
      ctesRoutes: (state) => state.routes.ctesRoutes,
      page: (state) => state.pagination.page,
      paginate: (state) => state.pagination.paginate,
    }),
  },
  methods: {
    ...mapActions({
      fetchCtesRoutes: 'routes/fetchCtesRoutes',
      setLengthItems: 'pagination/setLengthItems',
      setPagination: 'pagination/setPagination',
      setLoading: 'loading/setLoading',
    }),

    //Init
    init(params) {
      this.search(params)
    },

    //Actions
    async search(values, loading = true) {
      //Check if click is on filters or paginate buttons
      if (values !== undefined) {
        this.filters = values
      }

      const params = {
        obj: {
          date_start: this.filters.dateRange.init,
          date_end: this.filters.dateRange.end,
          id: this.filters.id,
        },
        page: this.page,
        paginate: this.paginate,
      }

      //Loading
      if (loading) {
        this.setLoading(true)
      }

      await this.fetchCtesRoutes(params).then(() => {
        this.setPagination(this.ctesRoutes)
        this.checkLoadingAction(this.ctesRoutes)
      })

      this.$emit('update')
    },

    checkLoadingAction(table) {
      let hasLoadingAction = table?.data?.content.some((el) => el.actions.some(action => action.action_id === 'loading' && action.has_permission === true))
      if (hasLoadingAction) {
        if (!this.updating) {
          this.updating = true
          this.time = setTimeout(() => {
            this.search(undefined, false)
            this.updating = false
          }, this.timeOutUpdate + this.time)
          this.setPagination(table)
        }
      } else {
        clearTimeout(this.time)
        this.setPagination(table)
      }
    },

    //Handlers
    async handlerDownload(data) {
      this.setLoading(true)
      await ctes.downloadDacteZip({id: data.id}, (r) => r, (e) => e, () => this.setLoading(false))
    },

    async handlerDownloadXml(data) {
      this.setLoading(true)
      await ctes.downloadDacteXmlZip({id: data.id}, (r) => r, (e) => e, () => this.setLoading(false))
    },

    handleSee(data) {
      this.$router.push(`/routes/management/cte/${data.id}`)
    },

    handlerGenerate(data) {
      this.$refs.CteDialog.handlerOpen()
      this.$refs.CteDialog.handleGenerateAll(data)
    },

    handleRegenerateAll(data) {
      this.$refs.CteDialog.handlerOpen()
      this.$refs.CteDialog.handleRegenerateAll(data)
    },

    handleRemove(data) {
      this.$refs.CteDialog.handlerOpen()
      this.$refs.CteDialog.handleRemove(data)
    },

    //Actions
    async generate(data) {
      this.setLoading(true)
      await ctes.generateCtes({route_id: data.id}, (r) => {
        this.search()
        return r
      }, (e) => e, () => this.setLoading(false))
    },

    async regenerate(data) {
      this.setLoading(true)
      await ctes.regenerateCtes({route_id: data.id}, (r) => {
        this.search()
        return r
      }, (e) => e, () => this.setLoading(false))
    },

    async remove(data){
      await ctes.deleteCtes({id: data.id}, (r) => {
        this.search()
        return r
      }, (e) => e, () => this.setLoading(false))
    },

  },
}
</script>
