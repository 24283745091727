<template>
  <CteTable ref="CteTable" />
</template>
<script>
import CteTable from './CteTable'

export default {
  name: 'CTe',
  components: {
    CteTable,
  },
  methods: {
    //Init
    init(params) {
      this.search(params)
    },

    search(values) {
      this.$refs.CteTable.search(values)
      this.$emit('update')
    },

    //Handlers
    handlerNew() {
      this.$router.push('/routes/add/')
    },

  }
}
</script>
<style lang="scss" scoped>
</style>